<template>
  <CModal id="MediaModel" size="xl" :centered="true" :show.sync="MediaStoreConfig.Display">
    <CTabs :active-tab="0" addTabsWrapperClasses="tab-info mb-0" fade>
      <CTab :title="$t('Media.SelectImages')">
        <CRow class="mt-3 mx-n2">
          <CCol col="12">
            <CRow class="mx-n2">
              <CCol lg="4" md="6" class="px-2">
                <CInput type="text" size="sm" :placeholder="$t('Media.SearchInFolder')" v-model="SearchTerm" class="mb-2">
                  <template #append>
                    <CButton color="success" @click="GetList(Path)">{{ $t('Media.Search') }}</CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol lg="8" md="6" class="px-2">
                <CButton color="info" size="sm" class="mr-2" @click="GetList(Path)">
                  <CIcon name="cil-loop-circular" size="sm" /> {{ $t('Media.Refresh') }}
                </CButton>
                <CButton v-if="(MediaStoreConfig.Multiple === true)" :color="(ChosenAll === true ? 'success' : 'secondary')" size="sm" class="mr-2" @click="CheckAll()">
                  <CIcon name="cil-check" size="sm" /> {{ $t('Media.CheckAll') }}
                </CButton>
              </CCol>
            </CRow>
            <hr>
          </CCol>
          <CCol col="12">
            <CRow id="MediaStoreMain" :gutters="false" class="mx-n1">
              <CCol v-if="(Path !== '')" col="4" sm="4" md="3" xl="2" class="MediaItems px-1"
                    @click="PrevPage(AllPath.length)">
                <CCard class="border-0 p-2">
                  <CCardBody class="p-0 text-center">
                    <CIcon name="cil-action-undo" :height="60"/>
                  </CCardBody>
                  <CCardFooter class="text-center text-truncate px-1 py-0 pt-2 border-0">
                    {{ $t('Global.Back') }}
                  </CCardFooter>
                </CCard>
              </CCol>
              <template v-for="(dirData, index) in DirList">
                <CCol :key="`dir-${index}`" col="4" sm="4" md="3" xl="2" class="MediaItems">
                  <CCard class="border-0">
                    <CCardBody class="p-0 text-center" @click="GoPath(dirData)">
                      <CIcon name="cil-folder-open" :height="60"/>
                    </CCardBody>
                    <CCardFooter class="text-center text-truncate px-1 py-0 pt-2 border-0">
                      {{ dirData.Name }}
                    </CCardFooter>
                  </CCard>
                </CCol>
              </template>
              <template v-for="(fileData, index) in FileList">
                <CCol :key="index" col="4" sm="4" md="3" xl="2" class="MediaItems px-1">
                  <CCard class="border-0">
                    <input
                        :id="'Checkbox-Media-' + index + ComponentID"
                        :type="(MediaStoreConfig.Multiple === true ? 'checkbox' : 'radio')"
                        :value="fileData.Path"
                        v-model="MediaStoreConfig.ChooseImageList"
                        @change="ImagesHandler(fileData.Path)"
                        class="d-none">
                    <label :for="'Checkbox-Media-' + index + ComponentID">
                      <CCardImg :src="fileData.metadata.Thumbnail" thumbnail />
                      <CCardFooter class="text-center text-truncate px-1 py-0 pt-2 border-0">
                        {{ fileData.Name }}
                      </CCardFooter>
                    </label>
                  </CCard>
                </CCol>
              </template>
            </CRow>
          </CCol>
        </CRow>
        <CElementCover
            v-if="(Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner color="info"/>
        </CElementCover>
      </CTab>
      <CTab :title="$t('Media.DoUpload')">
        <Dropzone
            id="DropUpload"
            ref="DropUpload"
            :key="Path"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            class="mt-3"
            @vdropzone-complete-multiple="MultipleUpload"
        >
          <CIcon name="cil-image-plus" class="c-icon-custom-size" size="lg" />
          <span class="d-block">{{ $t('Global.DragAndUpload') }}</span>
        </Dropzone>
      </CTab>
    </CTabs>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Media.Title') }}</h5>
        <CButtonClose @click="Toggle(false)" />
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="Toggle(false)" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton @click="Confirm()" color="danger">{{ $t('Media.Confirm') }}</CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'MediaStore',
  props: ['Config'],
  layout: 'manage',
  components: {
    Dropzone: () => import('@/plugins/dropzone')
  },
  data () {
    return {
      ComponentID: null,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      ChosenAll: false,
      Display: false,
      Loading: true,
      Submit: false,
      UploadArea: false,
      Path: '',
      AllPath: [''],
      FileList: [],
      PageToken: '',
      File: [],
      Upload: {
        Message: '',
        State: null
      },
      DirList: [],
      SearchTerm: ''
    }
  },
  computed: {
    dropzoneOptions () {
      return {
        url: '/api/media/image/upload?token=' + localStorage.getItem('XX-CSRF-Token') + '&Path=' + this.Path,
        uploadMultiple: true,
        parallelUploads: 20,
        timeout: 30000,
        maxFiles: 20,
        maxFilesize: 1024 * 1024 * 3, // MB
        addRemoveLinks: true,
        headers: {
          'xx-csrf-token': localStorage.getItem('XX-CSRF-Token')
        }
      }
    }
  },
  mounted() {
    this.ComponentID = this._uid
    this.MediaStoreConfig = this.Config
  },
  watch: {
    'MediaStoreConfig.Display' (value) {
      if (value === true) {
        this.Init().then(() => {
          this.$Progress.finish()
        }).catch((err) => {
          this.$Progress.fail()
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Global/ErrorMessage') + err.msg,
            type: (err.type ? err.type : 'error')
          })
        })
      } else {
        this.DirList = []
        this.FileList = []
      }
    }
  },
  methods: {
    Init() {
      return Promise.all([
        // this.GetFiles(this.Path),
        this.GetList('')
      ]).then(() => {
        return true
      }).catch((err) => {
        console.log(err)
        throw err
      })
    },
    Toggle(status = true) {
      return new Promise((resolve) => {
        (status) ? this.$emit('Opening') : this.$emit('Closing')
        this.MediaStoreConfig.Display = status
        resolve()
      })
    },
    PrevPage(level) {
      if (level <= 0) return
      this.AllPath.splice((level - 1), 1)
      // this.GetFiles(this.AllPath[(level - 2)])
      this.GetList(this.AllPath[(level - 2)])
    },
    OpenFolder(path) {
      this.AllPath.push(path)
      // this.GetFiles(path)
      this.GetList(path)
    },
    GetList (Path = '') {
      this.Path = Path
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/media/image/list',
        method: 'post',
        data: {
          Path: Path + this.SearchTerm
        }
      }).then(({ data }) => {
        this.Loading = false
        this.DirList = (data.Data.prefixes || []).map(item => {
          return {
            Name: item.replace(Path, '').replace('/', ''),
            Path: item,
            Type: 'Dir'
          }
        })
        this.FileList = (data.Data.items || []).filter(item => item.contentType.includes('image') || item.contentType.includes('application')).map(item => {
          return {
            ...item,
            Name: item.name.replace(Path, '').replace('/', ''),
            Path: item.name,
            Type: 'File',
            ContentType: item.contentType,
            URL: this.$store.state.user.permission.StaticDomain + item.name,
            NewTime: item.timeCreated,
            UpdateTime: item.updated
          }
        }).filter(item => item.Name !== 'STORE')
      }).catch((error) => {
        throw error
      })
    },
    async GetFiles (path = 'Uploads', pageToken = false, pageItem = 40) {
      this.Path = path
      this.Loading = true

      const storageInstance = this.$store.state.userProjectApp.storage()
      const Query = { maxResults: pageItem }
      if (pageToken) Query.pageToken = this.PageToken
      else this.ResetState()

      const FileData = await storageInstance.ref(path).list(Query)
      FileData.prefixes.forEach((el) => {
        this.DirList.push({
          Name: el.name,
          Path: el.fullPath,
          Type: 'Dir'
        })
      })
      const processes = FileData.items.map((el) => {
        return this.GetFileMeta(el)
      })
      Promise.all(processes).then((result) => {
        result.forEach((el) => {
          if(el) this.FileList.push(el)
        })
      }).catch((error) => {
        console.log(error)
      })
      this.Loading = false
      this.PageToken = FileData.nextPageToken
    },
    GetFileMeta (file) {
      const storageInstance = this.$store.state.userProjectApp.storage()
      return storageInstance.ref().child(file.fullPath).getMetadata().then((MetaData) => {
        if (file.name !== 'STORE') {
          if (this.$store.state.user.permission.StaticDomain) {
            return {
              Name: file.name,
              Path: file.fullPath,
              Type: 'File',
              ContentType: MetaData.contentType,
              URL: this.$store.state.user.permission.StaticDomain + file.fullPath,
              NewTime: MetaData.timeCreated,
              UpdateTime: MetaData.updated
            }
          } else {
            return storageInstance.ref().child(file.fullPath).getDownloadURL().then((URL) => {
              return {
                Name: file.name,
                Path: file.fullPath,
                Type: 'File',
                ContentType: MetaData.contentType,
                NewTime: MetaData.timeCreated,
                UpdateTime: MetaData.updated,
                URL
              }
            }).catch((err) => {
              throw err
            })
          }
        }
      })
    },
    GoPath(fileData) {
      if (fileData.Type === 'Dir') {
        // this.GetFiles(fileData.Path)
        this.OpenFolder(fileData.Path)
      }
    },
    CheckAll() {
      if (this.ChosenAll) {
        this.ChosenAll = false
        this.MediaStoreConfig.ChooseImageList = []
      } else {
        this.ChosenAll = true
        this.MediaStoreConfig.ChooseImageList = this.FileList.filter(file => file.Type === 'File').map(file => file.Path)
      }
    },
    MultipleUpload(files) {
      console.log(files)
      setTimeout(() => {
        this.GetList(this.Path)
        this.$refs.DropUpload.removeAllFiles(true)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Media/UploadSuccess'),
          type: 'success'
        })
      }, 2000)
    },
    ImagesHandler(filePath) {
      if (!this.MediaStoreConfig.Multiple) {
        this.MediaStoreConfig.ChooseImageList = filePath
      }
    },
    ResetState() {
      this.FileList = []
      this.DirList = []
      this.MediaStoreConfig.ChooseImageList = []
      this.Submit = false
    },
    Confirm() {
      this.$emit('OK', this.MediaStoreConfig.ChooseImageList)
      if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
        this.$emit('SelectImages', this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$emit('SelectImage', this.MediaStoreConfig.ChooseImageList)
      }
      this.Toggle(false)
    }
  }
}
</script>

<style lang="scss">
#MediaModel {
  z-index: 1301;
  position: relative;
}

@media (min-width: 1600px) {
  #MediaStoreMain {
    .MediaItems {
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
  }
}

#MediaStoreMain {
  min-height: 50vh;
  overflow-y: auto;
  .MediaItems {
    cursor: pointer;
    &label {
      cursor: pointer;
    }
    .c-icon-custom-size {
      width: 75%;
    }
    .card {
      margin-bottom: 15px;
    }
    .img-thumbnail.card-img {
      height: 60px;
      object-fit: cover;
    }
    input[type=checkbox] + label, input[type=radio] + label {
      padding: 0.5rem;
    }
    input[type=checkbox]:checked + label, input[type=radio]:checked + label {
      background-color: #eee;
    }
  }
}
</style>
